.about-section{
  overflow-x: hidden;
}
.about_banner {
  margin-top: 90px;
 display: flex;
 min-height: 20vh;
 justify-content: center;
 align-items: center;
 background-color: #4d6cb1;
 position: relative;
 
}
.about_banner h1{
  position: absolute;
  /* margin-top: 80px; */
  color: #fff !important;
  /* padding: 100px 0; */
}



/* Product Part */
.about_product_part {
  margin-top: 100px;
}
.about_product_part_left {
  display: flex;
  justify-content: center;
  background-image: url("https://res.cloudinary.com/dqlx1vyne/image/upload/v1677231889/PlusEra/Collage-01_hhb9qv.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.about_product_part_right {
  padding: 10px 50px 0px 70px;
}
.about_product_part_right p {
  margin-top: 20px;
}
.about_product_part_right h2 {
  font-weight: 600;
  font-size: 40px;
  color: #478549;
  position: relative;
}
.about_product_part_right button {
  border: none;
  padding: 12px 30px;
  background-color: #478549;
  border-radius: 5px;
  color: #fff;
}

/* Choose */
.about_choose {
  background-color: #ecf7f9;
  margin-top: 50px;
  padding: 80px 0;
}

.title h2 {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 40px;
  color: #478549;
  position: relative;
}

.about_choose p,
.services p,
.about_review p {
  margin-top: 35px;
  padding: 0px 300px;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
}
.about_choose h2 {
  color: #4d6cb1;
}
.about_choose_icons {
  margin-top: 80px;
}
.about_choose_icons img {
  position: relative;
  z-index: 2;
}
.icon-s {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-bottom: 30px;
  line-height: 100px;
  border-radius: 50px;
  position: relative;
}
.icon-s:before {
  content: "";
  width: 100px;
  height: 100px;
  background: #fff;
  display: inline-block;
  margin-bottom: 30px;
  line-height: 100px;
  border-radius: 50px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 22%);
}
.icon-s:after {
  content: "";
  position: absolute;
  width: 120px;
  height: 60px;
  /* background: #ffc53c; */
  background: #4d6cb1;
  left: -10px;
  bottom: -10px;
  border-radius: 0 0 100px 100px;
}
.about_choose_icons p {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  color: #4d6cb1;
}

/* Cards */
.about_cards {
  margin-top: 100px;
}
.about_card_img {
  width: 180px;
  border: 5px solid #478549;
  padding: 0px;
  border-radius: 50%;
}
.about_cards .card {
  display: flex;
  align-items: center;
  text-align: center;
  border: none;
  gap: 15px;
  padding: 10px 20px; 
}
.about_cards .fade-right{
  padding: 30px;
}
.about_cards .card-title {
  color: #478549;
  font-size: 22px;
}

/* Services */
.services {
  margin-top: 80px;
}
.services h2 {
  color: #478549;
}
.service_slider_right {
  margin-top: 110px;
  padding: 30px 50px !important;
  background-color: #f8f8f8;
}
.service_slider_right h2 {
  color: #478549;
  font-weight: 600;
}
.service_slider_left {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 50px 0px;
}
.service_slider_left:before {
  content: "";
  position: absolute;
  width: 73%;
  height: 80%;
  border: 4px solid #478549;
  bottom: 0%;
  left: 19.1%;
  z-index: -1;
}
.service_slider_left img {
  width: 400px;
}
.slider {
  position: relative;
}
.slick-arrow {
  color: red !important;
  background-color: red !important;
}

/* Slider */
.custom_slider_btn {
  display: flex;
  float: right;
  padding: 0 50px;
  cursor: pointer;
}
.custom_slider_btn button {
  border-radius: 50%;
  padding: 5px 10px;
  margin: 5px;
  border: 2px solid #478549;
}
.custom_slider_btn button svg {
  color: #478549;
}



/* Certified */
.certified {
  background-image: url("https://res.cloudinary.com/dqlx1vyne/image/upload/v1675748232/PlusEra/aboutbannermid_zt1r2w.jpg");
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  height: 60vh;
  margin-top: 100px;
}
.certified .certified_background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ecf7f9;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 1000px;
  height: 300px;
}
.certified .certified_background h1 {
  color: #478549;
}
.certified .certified_background button {
  background-color: #478549;
  padding: 8px 30px;
  border: none;
  color: #fff;
}

/* about_review */
.about_review {
  margin-top: 100px;
}
.about_review h2 {
  color: #4d6cb1;
}
@media screen and (max-width: 1200px) {
  .hero-text {
    padding: 20rem 6rem;
  }
  .hero_banner_text {
    top: 20rem;
  }
  .about_choose p,
  .services p,
  .about_review p {
    padding: 0px 0px;
  }
 
}
@media screen and (max-width: 992px) {
  .about_product_part {
    margin-top: 50px;
  }
  .hero-text {
    padding: 20rem 2rem;
  }
 
  .home_product_part_right {
    padding: 10px 0px 0px 20px;
  }
  .home_product_part_right h2 {
    font-size: 30px;
  }

  .service_slider_left:before {
    bottom: 10%;
    left: 13.1%;
  }
  .service_slider_right {
    margin-top: 0px;
    padding: 10px 10px !important;
    background-color: #f8f8f8;
  }

  .ourproductLeft {
    padding: 50px 10px 50px 30px;
  }
 
  .certified .certified_background {
    padding: 10px 50px;    
    width: 700px;
    height: 300px;
    gap: 10px;
  }
  .about_product_part_left {
    min-height: 400px;
   }
}
@media screen and (max-width: 768px) {
  .hero-text {
    padding: 20rem 2rem;
  }
  .home-section .hero-text .container {
    margin: 0;
  }
  .ourProduct {
    display: none;
  }
  
}
@media screen and (max-width: 576px) {
  .hero-text {
    padding: 15rem 2rem;
  }
  .hero-text h1 {
    font-size: 50px;
  }
  .hero_banner_text {
    top: 12rem;
    left: 1rem;
  }
  .about_product_part_right {
    padding: 70px 30px 0px 30px;
  }
  .certified .certified_background {
    padding: 20px 50px;    
    width: 400px;
    height: 350px;
    gap: 10px;
  }
  .certified .certified_background h1 {
    color: #478549;
    font-size: 20px;
  }
  .certified .certified_background p{
    font-size: 14px;
  }
  .about_review  .title h2{
    font-size: 27px;
  }
}
@media screen and (max-width: 391px) {
  .hero-text {
    padding: 12rem 2rem;
  }
  .hero-text h1 {
    font-size: 50px;
  }
  .hero_banner_text {
    top: 11rem;
    left: 1rem;
  }
  .about_product_part_right {
    padding: 0px 10px 0px 10px;

  }
  .about_review  .title h2{
    font-size: 25px;
  }
}
