@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);

* { 
  box-sizing: border-box; 
}

html,body{
  height: 100%;
}


h1{
  margin: 0;
} 





.overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 100;
}

.wrapper{
  width: 100px;
  height: 100px;
  background: white;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3px;
}

.box{
  width: 50%;
  height: 50%;
  flex: 50%;
  transition: 0.5s;
  border: 2px solid white;
}

.box:nth-child(1){
  animation: load 1s infinite;
}

.box:nth-child(2){
  animation: load2 1s infinite;
  animation-delay: 0.2s;
}

.box:nth-child(3){
  animation: load3 1s infinite;
  animation-delay: 0.7s;
}

.box:nth-child(4){
  animation: load4 1s infinite;
  animation-delay: 0.5s;
}

@keyframes load {
  0% { background: rgba(231, 76, 60, 0); }
  30% { background: rgb(42, 99, 62); }

  100% { background: rgba(231, 76, 60, 0); }
}

@keyframes load2 {
  0% { background: rgba(46, 204, 113, 0); }
  30% { background: rgb(46, 204, 113); }

  100% { background: rgba(46, 204, 113, 0); }
}

@keyframes load3 {
  0% { background: rgba(230, 126, 34, 0); }
  30% { background: rgb(51, 64, 115); }

  100% { background: rgba(230, 126, 34, 0); }
}

@keyframes load4 {
  0% { background: rgba(241, 196, 15, 0); }
  30% { background: rgb(78, 90, 163); }

  100% { background: rgba(241, 196, 15, 0); }
}
